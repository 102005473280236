// tslint:disable-next-line:no-submodule-imports
import { all } from "redux-saga/effects";
import apiSaga from "./api";
import logActionsWatcher from "./logActions";
import pocketbookSaga from "./pocketbook";
import awsMarketplaceSaga from "./awsmarketplace";

export default function* rootSaga() {
    yield all([
        pocketbookSaga(),
        apiSaga(),
        logActionsWatcher(),
        awsMarketplaceSaga(),
    ]);
}
