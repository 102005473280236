import { alert } from "../../components/global/alert/Alert";
import { all, takeEvery } from "redux-saga/effects";
import t from "../../i18n/i18next";
import { submitAwsMarketplaceActivation } from "../actions/awsmarketplace";

function* alertSuccess() {
    alert(
        t(
            "You have successfully linked your AWS Marketplace subscription.  Please log out and log back in to see your updated account."
        )
    );
    yield;
}

function* awsMarketplaceSaga() {
    yield all([
        takeEvery(submitAwsMarketplaceActivation.type_success, alertSuccess),
    ]);
}

export default awsMarketplaceSaga;
