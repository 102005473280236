import { createReducer } from "@reduxjs/toolkit";
import { ILicense } from "../../components/licenses/Licenses";
import {
    closeSubscriptionModal,
    createSubscription,
    createTrial,
    listLicenses,
    openSubscriptionModal,
} from "../actions/licenses";
import { logOut } from "../actions/user";

export interface ILicensesReduxSubscriptionSuccess {
    invoiceUrl?: string;
    newSubscription: boolean;
}

export interface ILicensesReduxState {
    show: boolean;
    license?: ILicense;
    licenses?: ILicense[];
    listError?: string;
    subscriptionSuccess?: ILicensesReduxSubscriptionSuccess;
    subscriptionError?: string;
}

const getDefaultState = (): ILicensesReduxState => {
    return { show: false, licenses: [] };
};

export const licenses = createReducer(getDefaultState(), (builder) => {
    builder.addCase(listLicenses.success, (state, action) => {
        state.licenses = action.payload;
    });
    builder.addCase(listLicenses.error, (state, action) => {
        state.listError = action.payload.response.response;
    });
    builder.addCase(createTrial.success, (state, action) => {
        state.licenses = action.payload.licenses;
        state.subscriptionSuccess = {
            invoiceUrl: action.payload.invoiceUrl,
            newSubscription: action.payload.newSubscription,
        };
    });
    builder.addCase(createSubscription.success, (state, action) => {
        state.licenses = action.payload.licenses;
        state.subscriptionSuccess = {
            invoiceUrl: action.payload.invoiceUrl,
            newSubscription: action.payload.newSubscription,
        };
    });
    builder.addCase(createSubscription.error, (state, action) => {
        state.subscriptionError = action.payload.response.response;
    });
    builder.addCase(openSubscriptionModal, (state, action) => {
        state.show = true;
        state.license = action.payload;
    });
    builder.addCase(closeSubscriptionModal, (state) => {
        state.show = false;
        state.license = undefined;
        state.subscriptionSuccess = undefined;
        state.subscriptionError = undefined;
    });
    builder.addCase(logOut.request, () => {
        return getDefaultState();
    });
});
